import React, { createContext, useCallback, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';


import { useLoading } from 'hooks';
// import { useEncoder, useToast, useChanges, useLoading } from 'hooks';
import externalAPI from 'service/auth';

import localStorageItems from './localStorageItems';
import endpoint from 'service/endpoint';

const AuthContext = createContext({});
function AuthProvider({ children }) {
  const { REACT_APP_TOKEN_ENDPOINT } = process.env;
  const { toggleLoading } = useLoading();
  // const { et64, df64 } = useEncoder();
  const navigate = useNavigate();
  // const guildid = REACT_APP_API_DISCORD_GUILD;
  const tokenEndpoint = REACT_APP_TOKEN_ENDPOINT;
  // const baseEndpoint = REACT_APP_API_ENDPOINT;
  // const user_endpoint_path = `users/@me`;
  // const guild_info_endpoint_path = `users/@me/guilds/${guildid}/member`;

  const [data, setData] = useState(() => {
    const token = localStorage.getItem(`${process.env.REACT_APP_TOKEN}`);
    const user = localStorage.getItem(`${process.env.REACT_APP_USER}`);

    if (token && user) {


      return { token, user: JSON.parse(user) };
    }

    return {};
  });

  const getCredentials = useCallback(async ({ code }) => {
    // try {
    toggleLoading();
    const send = new FormData();
    send.append('grant_type', 'authorization_code');
    send.append('code', code);
    send.append('redirect_uri', process.env.REACT_APP_RETURN_URL);
    send.append('client_id', process.env.REACT_APP_DISCORD_CLIENT_ID);
    send.append('client_secret', process.env.REACT_APP_DISCORD_CLIENT_SECRET);

    const response = await externalAPI.post(`${tokenEndpoint}/token`, send, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    // const authResponse = await endpoint.post('/user/signin', response.data)

    const authResponse = await endpoint.post('/user/signin', response.data)
      .then(
        (r) => {
          toggleLoading();
          return r;
        }
      )
      .catch((err) => {
        if(err.message && err.message.includes('banned')){
          // NAVIGATE TO BAN ISSUED
          navigate(`/ban-issued`, { replace: true });
        }

        if(err.message && err.message.includes('not part of')){
          // NAVIGATE TO NOT PART OF GUILD
          navigate(`/not-a-member`, { replace: true });
        }
        toggleLoading();
        throw new Error(err.message);
      })

      const baseData = {
        token: authResponse.token,
        user: authResponse.enrichedUser,
      };

      setData({ ...baseData });

      return authResponse;
  }, []);

  const signOut = useCallback(async () => {



    try {
      await endpoint.post('/user/signout')
    } catch(e) {
//
    }
    finally {
      localStorageItems.forEach((element) => {
        localStorage.removeItem(element);
      });
      setData({});
      navigate(`/`, { replace: true });
    }



    // const send = new FormData();
    // send.append('token', data.user.access_token);
    // send.append('client_id', process.env.REACT_APP_DISCORD_CLIENT_ID);
    // send.append('client_secret', process.env.REACT_APP_DISCORD_CLIENT_SECRET);

    // await externalAPI
    //   .post(`${tokenEndpoint}/token/revoke`, send, {
    //     headers: {
    //       'Content-Type': 'application/x-www-form-urlencoded',
    //     },
    //   })
    //   .then(() => {
    //     localStorageItems.forEach((element) => {
    //       localStorage.removeItem(element);
    //     });

    //     setData({});

    //     navigate(`/`, { replace: true });
    //   });
  }, [navigate, data]);

  return (
    <AuthContext.Provider
      value={{
        token: data.token,
        user: data.user,
        getCredentials,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { useAuth, AuthProvider };
