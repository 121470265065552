import React from 'react';
import { FaExternalLinkAlt, FaTimesCircle } from 'react-icons/fa';
import { FaDiscord } from 'react-icons/fa6';

function NotMember({type = 'not-a-member'}) {
  const { REACT_APP_DISCORD_APPLICATION_URL, REACT_APP_DISCORD_BAN_ISSUE_URL } = process.env

  return type === 'ban' ? <Banned link={REACT_APP_DISCORD_BAN_ISSUE_URL}/> : <Base link={REACT_APP_DISCORD_APPLICATION_URL}/>
}

function Banned({link}) {
  return (
    <div className="flex justify-center items-center mt-16">
      <div className="max-w-md w-full shadow-xl rounded-2xl border border-red-500">
        <div className="flex flex-col items-center justify-center bg-red-500 text-white text-center py-4 rounded-t-2xl">
          <FaTimesCircle className='' size={40}/>
          <h1 className="text-xl font-semibold">Você foi BANIDO!</h1>
        </div>
        <div className="p-6 text-center bg-red-500/15">
          <p className="text-gray-100 text-lg">Seu acesso foi suspenso devido a violações das regras da comunidade.</p>
          <a href={link} className="">
            <div className='flex flex-row items-center justify-center text-sm text-blue-300 mt-2 underline'>
            Clique aqui para acessar o formulário de contato.
            <FaExternalLinkAlt className='ml-2'/>
            </div>
          </a>
          {/* <p className="text-sm text-gray-500 mt-2">Caso tenha dúvidas, entre em contato com o suporte.</p> */}
        </div>
      </div>
    </div>
  )
}

function Base({link}) {
  return (
    <div className="flex justify-center items-center mt-16">
      <div className="max-w-md w-full shadow-xl rounded-2xl border border-indigo-600">
        <div className="flex flex-col items-center justify-center bg-indigo-600 text-white text-center py-4 rounded-t-2xl">
          <FaDiscord className='' size={40}/>
          <h1 className="text-xl font-semibold">Junte-se à Nossa Comunidade!</h1>
        </div>
        <div className="p-6 text-center bg-indigo-600/15">
          <p className="text-gray-100 text-lg">Parece que você ainda não faz parte da nossa comunidade.</p>
          <a href={link} className="">
            <div className='flex flex-row items-center justify-center text-sm text-blue-300 mt-2 underline'>
              Clique aqui para preencher o formulário de inscrição.
              <FaExternalLinkAlt className='ml-2'/>
            </div>
          </a>
        </div>
      </div>
    </div>
      );

}

export default NotMember;
